import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { getAccessToken } from './getAccessToken';

export const getProfilePicture = async (account: AccountInfo, pca: PublicClientApplication) => {
  const graphDefaultToken = await getAccessToken(
    account,
    ['https://graph.microsoft.com/.default'],
    pca,
  );

  return fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
    headers: { Authorization: `Bearer ${graphDefaultToken}` },
  }).then((res) => res.blob().then((blob) => URL.createObjectURL(blob)));
};
