import dayjs from 'dayjs';
import { FilteredRxsWinProps, ToggleTabsValues } from '~constants';

export const filterRxsWinByActiveTab = (
  data: FilteredRxsWinProps[],
  selectedTab: ToggleTabsValues,
) => {
  const now = dayjs();
  if (selectedTab === ToggleTabsValues.LastSixWeeks)
    return data.filter((item) => dayjs(item.date) > now.subtract(6, 'weeks'));
  if (selectedTab === ToggleTabsValues.ThreeMonths)
    return data.filter((item) => dayjs(item.date) > now.subtract(3, 'months'));
  if (selectedTab === ToggleTabsValues.SixMonths)
    return data.filter((item) => dayjs(item.date) > now.subtract(6, 'months'));
  return [];
};
