import { useContext, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { LicenseManager } from 'ag-grid-enterprise';
import { TelemetryProvider } from './containers/TelemetryContext';
import { createAppInsightsTelemetry, telemetryClient } from '~utils/telemetry';
import { createApolloClient } from './apollo';
import { MsalContext } from '~utils/msal';
import { env } from '~config/index';
import './styles/aggrid-styles.scss';
import './styles/styles.scss';

import { NavBar, RoleProtectedRoute } from '~components';
import { AuditableRegister, Declarations, NotFound } from '~views';
import { getTheme } from '~config/theme/theme';
import { ColorModeContext } from '~utils/colorMode';

LicenseManager.setLicenseKey(env.agGridLicenseKey);

const App = () => {
  const { mode } = useContext(ColorModeContext);
  const { token, account, roles, profilePicture } = useContext(MsalContext);
  const theme = useMemo(() => getTheme(mode), [mode]);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <TelemetryProvider value={createAppInsightsTelemetry(telemetryClient)}>
          <CssBaseline />
          <ApolloProvider client={createApolloClient(token)}>
            <NavBar userName={account?.name} profilePicture={profilePicture} roles={roles} />
            <Routes>
              <Route
                path="/"
                element={
                  <RoleProtectedRoute roles={roles}>
                    <Declarations />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/auditable-register"
                element={
                  <RoleProtectedRoute roles={roles}>
                    <AuditableRegister />
                  </RoleProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ApolloProvider>
        </TelemetryProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
