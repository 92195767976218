import { PaletteMode, useMediaQuery } from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { setCssVariables } from '~styles/setCssVariables';
import { ColorModeContext } from '.';

const getInitialColorMode = (prefered: PaletteMode): PaletteMode => {
  if (localStorage.getItem('colorMode')) {
    return localStorage.getItem('colorMode') as PaletteMode;
  }
  return prefered;
};

export const ColorModeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const systemPreferredColorScheme = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light';
  const [mode, setMode] = useState<'light' | 'dark'>(
    getInitialColorMode(systemPreferredColorScheme),
  );
  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode((prevMode) => {
          const nextMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('colorMode', nextMode);
          return nextMode;
        });
      },
    }),
    [mode],
  );

  useEffect(() => {
    setCssVariables(mode);
  }, [mode]);

  return <ColorModeContext.Provider value={colorMode}>{children}</ColorModeContext.Provider>;
};
