import { createRoot } from 'react-dom/client';
import { MsalProvider } from './utils/msal';
import App from './App';
import { ColorModeProvider } from '~utils/colorMode';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
  <MsalProvider>
    <ColorModeProvider>
      <App />
    </ColorModeProvider>
  </MsalProvider>,
);
