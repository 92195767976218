import { Box, Card, Typography, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '250px',
          width: '650px',
          padding: theme.spacing(3),
        }}
      >
        <Typography variant="h6">PAGE NOT FOUND</Typography>
        <Typography variant="body1" sx={{ marginBottom: theme.spacing(3) }}>
          We&apos;re sorry, but the page you&apos;re looking for is currently unavailable.
        </Typography>
        <Button
          variant="outlined"
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.secondary.lighter,
            },
          }}
          onClick={() => navigate('/')}
        >
          Return home
        </Button>
      </Card>
    </Box>
  );
};
