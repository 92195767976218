import { ColDef } from 'ag-grid-community';
import { AccessLevel } from '~codegen/types-and-hooks';
import { FormattedAuditableRegisterRxsWin } from '~constants';
import { FiltersProps } from '~views';

interface ExternalHeaders {
  baseRxsWin?: string;
  rxsWin: string;
  market: string;
  approvalNumber: string;
  softwarePartNumber: string;
  regulation: string;
  brand?: string;
  softwareType: string;
  consumerSoftwareVersion: string;
  ecuShortName?: string;
  integrityValidationData?: string;
  relevantSWPackageIDAndVersion?: string;
  systemDescription?: string;
}

interface InternalHeaders extends ExternalHeaders {
  softwareSupplierPartNumber?: string;
  relevantSWPackageName?: string;
  ecuPartNumber?: string;
  ecuHomologationNumber?: string;
  ecuCorePartNumber?: string;
  ecuCoreSupplierPartNumber?: string;
  vehicleSpec?: string;
  complianceAssessmentReport?: string;
  baselineName?: string;
}

export const getAuditableRegisterDatagrid = (
  rxsWin: FormattedAuditableRegisterRxsWin[],
  groupByValue: string,
  filters: FiltersProps,
) => {
  const formattedRxsWin = rxsWin.map(
    ({
      rxsWin,
      market,
      approvalNumber,
      softwarePartNumber,
      softwareSupplierPartNumber='',
      regulation,
      softwareType,
      consumerSoftwareVersion,
      brand,
      checksumMethod,
      vbfChecksum,
      ecuShortName = '',
      softwarePackageName: relevantSWPackageName = '',
      softwarePackageNameId,
      version,
      systemDescription = '',
      ecuPartNumber = '',
      ecuHomologationNumber = '',
      ecuCorePartNumber = '',
      ecuCoreSupplierPartNumber = '',
      vehicleSpec = '',
      complianceAssessmentReport = '',
      baselineName = '',
    }) => ({
      rxsWin,
      market,
      approvalNumber,
      softwarePartNumber,
      softwareSupplierPartNumber: softwareSupplierPartNumber || '',
      regulation,
      softwareType: softwareType || '',
      consumerSoftwareVersion: consumerSoftwareVersion || '',
      brand,
      ecuShortName,
      integrityValidationData: `${checksumMethod}: ${vbfChecksum}`,
      relevantSWPackageName: relevantSWPackageName || '',
      relevantSWPackageIDAndVersion: (softwarePackageNameId == null || ['0', 'N/A'].includes(softwarePackageNameId) ? 'N/A' : `ID ${softwarePackageNameId} Ver ${version}`),
      systemDescription: systemDescription || '',
      ecuPartNumber: ecuPartNumber || 'N/A',
      ecuHomologationNumber: ecuHomologationNumber || '',
      ecuCorePartNumber,
      ecuCoreSupplierPartNumber: ecuCoreSupplierPartNumber || '',
      vehicleSpec: vehicleSpec || '',
      ...(groupByValue === 'baseRxsWin' && {
        baseRxsWin: rxsWin.includes('_') ?
          rxsWin.slice(0, rxsWin.lastIndexOf('_')) : // for Volvo produced cars
          rxsWin.slice(0, rxsWin.length - 2), // for Geely produced cars
      }),
      complianceAssessmentReport: complianceAssessmentReport || '',
      baselineName: baselineName || '',
    }),
  );

  return filters.softwarePartNumber
    ? formattedRxsWin.filter(({ softwarePartNumber }) =>
      softwarePartNumber.includes(filters.softwarePartNumber),
    )
    : formattedRxsWin;
};

export const getAuditableRegisterHeaders = (
  groupByValue: string,
  filters: FiltersProps,
  accessLevel?: AccessLevel,
) => {
  const externalHeaders: ExternalHeaders = {
    baseRxsWin: 'Base RXSWIN',
    rxsWin: 'RXSWIN',
    // change the name of the column market to 'Certification Market'
    market: 'Certification Market',
    approvalNumber: 'Type Approval',
    regulation: 'Regulation',
    ecuShortName: 'ECU Name',
    softwareType: 'SW Type',
    softwarePartNumber: 'SW Part Number',
    ...(accessLevel===AccessLevel.Internal && { softwareSupplierPartNumber: 'SW Part Number Supplier' }), // only for internal to maintain order of column added here
    ...(!!filters.brand && filters.brand.length > 1 && { brand: 'Brand' }),
    consumerSoftwareVersion: 'Consumer SW Version',
    integrityValidationData: 'Integrity Validation Data',
    systemDescription: 'System Description',
    relevantSWPackageIDAndVersion: 'Relevant SW Package ID and Version',
  };
  const { consumerSoftwareVersion, ...restExternalHeaders } = externalHeaders;
  const internalHeaders: InternalHeaders = {
    ...restExternalHeaders,
    consumerSoftwareVersion,
    relevantSWPackageName: 'Relevant SW Package Name',
    ecuPartNumber: 'ECU Part Number',
    ecuHomologationNumber: 'ECU Homologation Number',
    ecuCorePartNumber: 'ECU Core Part Number',
    ecuCoreSupplierPartNumber: 'ECU Core Part Number Supplier',
    vehicleSpec: 'Vehicle spec',
    complianceAssessmentReport: 'Compliance Assessment Report',
    baselineName: 'Baseline',
  };

  const accessHeaders = accessLevel === AccessLevel.Internal ? internalHeaders : externalHeaders;

  const columnDefs: ColDef[] = Object.keys(accessHeaders).map((key) => ({
    field: key,
    headerName: accessHeaders[key as keyof (ExternalHeaders | InternalHeaders)],
    tooltipField: key,
    rowGroup: false,
    hide: false,
    ...(((groupByValue === 'baseRxsWin' && key === 'baseRxsWin') ||
      (groupByValue === 'softwarePartNumber' && key === 'softwarePartNumber')) && {
      rowGroup: true,
      hide: true,
    }),
  }));
  return columnDefs;
};
