/* istanbul ignore file */
// Ignoring file since we do not need to test this implmementation since it's a wrapper for appInsights from microsoft.

import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { Telemetry } from './Telemetry';

export const createAppInsightsTelemetry = (
  appInsights: ApplicationInsights
): Telemetry => ({
    trackEvent: (name, customProperties): void => {
      appInsights.trackEvent({ name }, customProperties);
    },
    trackException: (error): void => {
      appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
    },
    trackTrace: (message, customProperties): void => {
      appInsights.trackTrace(
        {
          message,
          severityLevel: SeverityLevel.Information,
        },
        customProperties
      );
    },
    startTrackEvent: (name): void => {
      appInsights.startTrackEvent(name);
    },
    stopTrackEvent: (name): void => {
      appInsights.stopTrackEvent(name);
    },
    trackPageView: (uri): void => {
      appInsights.trackPageView({ uri });
    },
  });
