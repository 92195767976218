import VolvoNovumRegularWoff from './VolvoNovum-Regular.woff';
import VolvoNovumRegularWoff2 from './VolvoNovum-Regular.woff2';
import VolvoNovumItalicWoff from './VolvoNovum-Italic.woff';
import VolvoNovumItalicWoff2 from './VolvoNovum-Italic.woff2';
import VolvoNovumMediumWoff from './VolvoNovum-Medium.woff';
import VolvoNovumMediumWoff2 from './VolvoNovum-Medium.woff2';
import VolvoBroadWebWoff from './VolvoBroadWeb.woff';
import VolvoBroadWebWoff2 from './VolvoBroadWeb.woff2'

const novumBase = {
  fontFamily: 'Volvo Novum',
  fontStyle: 'normal',
};

export const fonts = [
  {
    ...novumBase,
    fontWeight: 500,
    src: `
    url(${VolvoNovumMediumWoff2}) format('woff2'),
    url(${VolvoNovumMediumWoff}) format('woff');
   `,
  },
  {
    ...novumBase,
    fontWeight: 'normal',
    src: `
    url(${VolvoNovumRegularWoff2}) format('woff2'),
    url(${VolvoNovumRegularWoff}) format('woff');
   `,
  },
  {
    ...novumBase,
    fontWeight: 'normal',
    fontStyle: 'italic',
    src: `
    url(${VolvoNovumItalicWoff2}) format('woff2'),
    url(${VolvoNovumItalicWoff}) format('woff');
   `,
  },
  {
    fontFamily: 'Volvo Broad',
    src: `
    url(${VolvoBroadWebWoff2}) format('woff2'),
    url(${VolvoBroadWebWoff}) format('woff');
  `,
  },
];
