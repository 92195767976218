import { ComponentsOverrides } from '@mui/material/styles';
import { DEFAULT_GUTTERS } from './constants';
import { fonts } from './fonts';

export const overrides: ComponentsOverrides = {
  MuiContainer: {
    root: {
      overflow: 'hidden',
    },
  },
  MuiCardContent: {
    root: {
      padding: DEFAULT_GUTTERS,
      '&:last-child': {
        paddingBottom: DEFAULT_GUTTERS,
      },
    },
  },
  MuiCard: {
    root: {
      boxShadow: '0 0 24px 0 rgba(0,0,0,0.08)',
      borderRadius: '1px',
    },
  },
  MuiListItem: {
    root: {
      textAlign: 'center',
    },
  },
  MuiLink: {
    root: {
      width: '100%',
    },
  },
  MuiButtonBase: {
    root: {
      color: 'inherit',
    },
  },
  MuiIconButton: {
    root: {
      color: 'inherit',
    },
  },
  MuiButton: {
    root: {
      color: 'inherit',
    },
    outlined: {
      border: '1px solid currentColor',
    },
  },
  MuiCssBaseline: {
    '@global': {
      '@font-face': fonts,
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: '.6em',
    },
  },
};
