import { Grid, IconButton, useTheme, Box } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';

interface DrawerHeaderProps {
  onClose: () => void;
}

export const DrawerHeader = ({ onClose }: DrawerHeaderProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        minWidth: '250px',
      }}
    >
      <Grid item>
        <Box component="img" src="/assets/volvo.svg" alt="Volvo Cars Logo" sx={{ width: 100 }} />
      </Grid>
      <Grid item>
        <IconButton onClick={onClose} edge="end">
          <GridCloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
