import { useState } from 'react';
import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';
import { IStatusPanelParams } from 'ag-grid-community';

export const StatusPanelSearchBox = (props: IStatusPanelParams) => {
  const [quickFilterValue, setQuickFilterValue] = useState('');
  const { api } = props;

  const onQuickFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterValue(e.target.value);
    api.setQuickFilter(e.target.value);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <TextField
        size="small"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={onQuickFilterChangeHandler}
        value={quickFilterValue}
      />
    </Box>
  );
};
