interface MemoryVariables {
    graphqlEndpointUrl: string;
    appInsightsKey: string;
    auth: {
      tenant: string;
      clientId: string;
      resource: string;
    };
    publicPath: string;
    agGridLicenseKey: string;
  }

  export enum NodeEnv {
    development,
    test,
    production,
  }
  
  export enum AppEnv {
    qa,
    production,
    local,
  }
  
  interface Environment extends MemoryVariables {
    isDev: boolean;
    isProd: boolean;
    isTest: boolean;
  }
  
  export const getEnv = (): Environment => {
    let envConfig: MemoryVariables;
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case AppEnv[AppEnv.production]:
      case AppEnv[AppEnv.qa]:
      default: {
        envConfig = {
          graphqlEndpointUrl: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL || '',
          appInsightsKey: process.env.REACT_APP_APPINSIGHTS_KEY || '',
          auth: {
            tenant: process.env.REACT_APP_AUTH_TENANT || '',
            clientId: process.env.REACT_APP_AUTH_CLIENTID || '',
            resource: process.env.REACT_APP_AUTH_RESOURCE || '',
          },
          publicPath: process.env.REACT_APP_PUBLIC_PATH || '',
          agGridLicenseKey: process.env.REACT_APP_AGGRID_LICENSE_KEY || ''
        };
        break;
      }
    }
    return {
      isDev: process.env.NODE_ENV === NodeEnv[NodeEnv.development],
      isProd: process.env.NODE_ENV === NodeEnv[NodeEnv.production],
      isTest: process.env.NODE_ENV === NodeEnv[NodeEnv.test],
      ...envConfig,
    };
  };
  