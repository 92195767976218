import { useState } from 'react';
import { useTheme, AppBar, Box, Toolbar, styled, Typography, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Drawer, NavBarLinks, NavBarSettings } from '~components';

interface NavBarProps {
  profilePicture: string;
  roles: string[];
  userName?: string;
}

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
}));

export const NavBar = ({ userName, profilePicture, roles }: NavBarProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <AppBar sx={{ backgroundColor: theme.palette.background.paper }} position="static">
        <Toolbar
          disableGutters
          sx={{ display: 'flex', boxShadow: 1, padding: `0 ${theme.spacing(3)}` }}
        >
          <IconButton
            to="/"
            component={NavLink}
            aria-label="star logo"
            disableRipple
            sx={{
              mr: 1,
              paddingLeft: 0,
              '&:hover': {
                backgroundColor: theme.palette.background.paper,
              },
            }}
          >
            <Box
              component="img"
              src="/star_logo.svg"
              alt="stars logo"
              sx={{ marginRight: 'auto', height: 48, width: 43 }}
            />
          </IconButton>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 28,
                letterSpacing: 6,
                color: theme.palette.text.primary,
              }}
            >
              STAR
            </Typography>
          </Box>
          <StyledBox sx={{ justifyContent: 'flex-end', marginLeft: 'auto', alignItems: 'center' }}>
            {roles.includes('Guest') ? null : <NavBarLinks />}
            <NavBarSettings userName={userName} roles={roles} profilePicture={profilePicture} />
          </StyledBox>
        </Toolbar>
      </AppBar>
    </>
  );
};
