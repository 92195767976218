import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  AccountInfo,
} from '@azure/msal-browser';
import { signIn } from './signIn';

export const getAccessToken = async (
  account: AccountInfo,
  scopes: string[],
  pca: PublicClientApplication
): Promise<string | null> => {
  // If there is an already signed in user, get token silently.
  const silentToken = await pca
    .acquireTokenSilent({
      scopes,
      account,
    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // No signed in user.
        await signIn(pca);
      }
    });

  if (!silentToken) {
    return null;
  }

  // Return the token stored in cache
  return silentToken.accessToken;
};
