/* eslint-disable no-param-reassign */
/* istanbul ignore file */
// File contains setup for application insights, and wrappers. Nothing to test.

import { ComponentClass, FunctionComponent } from 'react';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { createBrowserHistory } from 'history';
import { env } from '../../config';
import { getAccount, loginConfiguration } from '../msal';

interface TelemetryService {
  reactPlugin: ReactPlugin;
  telemetryClient: ApplicationInsights;
}

const createTelemetryClient = (
  instrumentKey: string,
  userInfo: AccountInfo
): TelemetryService => {
  if (!instrumentKey)
    throw new Error('Instrument key is required for telemetry service.');
  const browserHistory = createBrowserHistory(window);
  const reactPlugin = new ReactPlugin();
  const telemetryClient = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentKey,
      maxBatchInterval: 100,
      maxMessageLimit: 10,
      extensions: [reactPlugin as unknown as ITelemetryPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  telemetryClient.loadAppInsights();
  telemetryClient.addTelemetryInitializer((envelope) => {
    if (envelope.tags != null) {
      envelope.tags['ai.cloud.role'] = 'STAR-FRONTEND';
    }
  });
  telemetryClient.setAuthenticatedUserContext(
    userInfo ? userInfo.username : 'anonymous',
    userInfo ? userInfo.homeAccountId : 'anonymous',
    true
  );

  return { reactPlugin, telemetryClient };
};

const { reactPlugin, telemetryClient } = createTelemetryClient(
  env.appInsightsKey,
  getAccount(new PublicClientApplication(loginConfiguration))
);

export { reactPlugin, telemetryClient };

type WithAITracker = (
  comp: FunctionComponent | ComponentClass,
  name: string
) => ComponentClass;

const withAI: WithAITracker = (comp, name) =>
  withAITracking(reactPlugin, comp, name);

export const withAIContainer: WithAITracker = (comp, name) =>
  withAI(comp, `Component: ${name}`);

export const withAIView: WithAITracker = (comp, name) =>
  withAI(comp, `View: ${name}`);
