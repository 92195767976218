import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box } from '@mui/material';
import { FilterListOff } from '@mui/icons-material';

import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  StatusPanelDef,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import {
  LoadingOverlay,
  NoRowsOverlay,
  StatusPanelGridButtons,
  StatusPanelSearchBox,
  DataGridHoverTooltip,
  StyledClearFilterButton,
} from '~components';

interface DataGridProps {
  formattedColumnDefs: ColDef[];
  formattedRowData?: Record<string, string>[] | null;
  loading: boolean;
  isAuditableRegisterView?: boolean;
  groupByValue?: string;
}

export const DataGrid = ({
  formattedColumnDefs,
  formattedRowData,
  loading,
  isAuditableRegisterView,
  groupByValue,
}: DataGridProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [gridApi, setGridApi] = useState<GridApi | null | undefined>();
  const [columnApi, setColumnApi] = useState<ColumnApi | null | undefined>();
  const [currentGridFilters, setCurrentGridFilters] = useState({});
  const gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      flex: isAuditableRegisterView ? 0 : 1,
      menuTabs: ['filterMenuTab'],
      tooltipComponent: !isAuditableRegisterView ? DataGridHoverTooltip : null,
      wrapText: true,
      autoHeight: true,
    },
  };

  const autoGroupColumnDef = {
    filter: true,
    filterValueGetter: (params: ValueGetterParams) =>
      groupByValue === 'baseRxsWin' ? params.data.baseRxsWin : params.data.softwarePartNumber,
  };

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    const gridButtons = {
      statusPanel: StatusPanelGridButtons,
      align: 'left',
      statusPanelParams: { gridApi },
    };

    const searchBox = {
      statusPanel: StatusPanelSearchBox,
      align: 'center',
      statusPanelParams: { gridApi },
    };

    return {
      statusPanels: isAuditableRegisterView ? [gridButtons, searchBox] : [searchBox],
    };
  }, []);

  const onGridReady = (params: GridOptions) => {
    setColumnApi(params.columnApi);
    setGridApi(params.api);
  };

  const onFilterChangedHandler = () => {
    if (gridApi) setCurrentGridFilters(gridApi.getFilterModel());
  };

  const onSelectionChangedHandler = () => {
    if (isAuditableRegisterView) return;
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      if (!selectedRows.length) return;
      navigate(`/auditable-register`, {
        replace: false,
        state: { softwarePartNumber: selectedRows[0].softwarePartNumber },
      });
    }
  };

  const columnResizeHandler = () => {
    if (isAuditableRegisterView) {
      setTimeout(() => {
        columnApi?.autoSizeAllColumns();
      }, 200);
    }
  };

  useEffect(() => {
    if (columnApi) {
      columnResizeHandler();
    }
  }, [groupByValue, columnApi]);

  useEffect(() => {
    if (loading) {
      gridApi?.showLoadingOverlay();
    } else if (formattedRowData && !formattedRowData.length) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [loading, gridApi]);

  return (
    <Box
      className="ag-theme-alpine"
      sx={{
        height: '100%',
        minHeight: '500px',
        width: '100%',
        position: 'relative',
        padding: {
          xs: `${theme.spacing(2)}`,
          sm: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
        },
      }}
    >
      {Object.keys(currentGridFilters).length ? (
        <StyledClearFilterButton gridApi={gridApi}>
          <FilterListOff />
        </StyledClearFilterButton>
      ) : undefined}
      <AgGridReact
        ref={gridRef}
        autoGroupColumnDef={autoGroupColumnDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        columnDefs={formattedColumnDefs}
        rowData={formattedRowData}
        statusBar={statusBar}
        groupDisplayType="multipleColumns"
        noRowsOverlayComponent={NoRowsOverlay}
        loadingOverlayComponent={LoadingOverlay}
        noRowsOverlayComponentParams={{
          isAuditableRegisterView,
          loading,
        }}
        onFilterChanged={onFilterChangedHandler}
        onSelectionChanged={onSelectionChangedHandler}
        tooltipShowDelay={500}
        tooltipMouseTrack={!isAuditableRegisterView}
        animateRows
        rowSelection="single"
        onExpandOrCollapseAll={columnResizeHandler}
        onRowDataUpdated={columnResizeHandler}
        onRowGroupOpened={columnResizeHandler}
      />
    </Box>
  );
};
