import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { env, msLogin } from '../../config';

export const loginConfiguration: Configuration = {
  cache: {
    cacheLocation: 'localStorage',
  },
  auth: {
    clientId: env.auth.clientId,
    authority: `${msLogin}/${env.auth.tenant}`,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [`${env.auth.resource}/user_impersonation`],
};

export const graphRequest: RedirectRequest = {
  scopes: ['user.read'],
};
