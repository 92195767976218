import { Divider, Drawer as MuiDrawer } from '@mui/material';
import { DrawerHeader, DrawerContent } from '~components';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

export const Drawer = ({ open, onClose }: DrawerProps) => (
  <MuiDrawer
    variant="temporary"
    anchor="right"
    sx={{ textAlign: 'center' }}
    open={open}
    onClose={onClose}
  >
    <DrawerHeader onClose={onClose} />
    <Divider />
    <DrawerContent />
  </MuiDrawer>
);
