import { Grid, styled } from '@mui/material';

export const StyledSelectableCardGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(1)}`,
  minHeight: '280px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    maxWidth: '280px',
  },
}));
