import { IStatusPanelParams } from 'ag-grid-community';
import { Box, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { Button } from '../Buttons';

export const StatusPanelGridButtons = (props: IStatusPanelParams) => {
  const theme = useTheme();
  const { api } = props;

  const expandAll = useCallback(() => {
    api.expandAll();
  }, []);

  const collapseAll = useCallback(() => {
    api.collapseAll();
  }, []);

  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Button
        data-testid="expand-button"
        sx={{
          ml: theme.spacing(2),
        }}
        variant="outlined"
        onClick={expandAll}
      >
        Expand
      </Button>
      <Button
        data-testid="collapse-button"
        sx={{
          ml: theme.spacing(2),
        }}
        variant="outlined"
        onClick={collapseAll}
      >
        Collapse
      </Button>
    </Box>
  );
};
