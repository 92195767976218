import { FilteredRxsWinProps, SelectableCardsValues } from '~constants';
import { filterNewRxsWin, filterUpdatedRxsWin } from '~helpers';

export const filterRxsWinBySelectedCard = (
  rxsWin: FilteredRxsWinProps[] | undefined,
  selectedCard: SelectableCardsValues,
) => {
  if (selectedCard === SelectableCardsValues.SWReleases) return rxsWin;
  if (selectedCard === SelectableCardsValues.NewRxsWins) return filterNewRxsWin(rxsWin);
  if (selectedCard === SelectableCardsValues.RxsWinUpdates) return filterUpdatedRxsWin(rxsWin);
  return [];
};
