import { useState, useEffect } from 'react';
import { FilterTypeValues } from '~constants';
import { FiltersProps } from '~views';

export const defaultFilters = {
  vin: '',
  brand: [],
  regulation: [],
  typeApprovals: '',
  rxsWin: '',
  softwarePartNumber: '',
};

const getStorageFilters = (): FiltersProps => 
  localStorage.getItem('filters') && !!JSON.parse(localStorage.getItem('filters') || '{}').regulation
    ? JSON.parse(localStorage.getItem('filters') || '{}')
    : defaultFilters;

const getFilteredBy = (): FilterTypeValues => localStorage.getItem('filteredBy') as FilterTypeValues || FilterTypeValues.Filters;

export const useStorageFilters = () => {
  const [storageFilters, setStorageFilters] = useState(() => getStorageFilters());
  const [filteredBy, setFilteredBy] = useState(() => getFilteredBy());
  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(storageFilters));
  }, [storageFilters]);

  useEffect(() => {
    localStorage.setItem('filteredBy', filteredBy);
  }, [filteredBy]);

  return {
    hasStorageFilters: Object.values(storageFilters).some((filter) => filter.length),
    storageFilters,
    filteredBy,
    setStorageFilters,
    setFilteredBy,
  };
};
