import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import React, { ReactNode, useEffect, useState } from 'react';
import { getAccessToken } from './getAccessToken';
import { getAccount } from './getAccount';
import { getProfilePicture } from './getProfilePicture';
import { loginConfiguration, loginRequest } from './msalConfig';
import { AuthorizationState, defaultAuthState, LoginState, MsalContext } from './MsalContext';
import { signIn } from './signIn';

const pca = new PublicClientApplication(loginConfiguration);

export const MsalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthorizationState>(defaultAuthState);
  const [loggedInAccount, setLoggedInAccount] = useState<AccountInfo | undefined>();

  const handleLoggedOutUser = async () => {
    setAuthState({
      ...authState,
      loginState: LoginState.LOGGED_OUT,
      account: null,
    });
    await signIn(pca);
  };

  useEffect(() => {
    const initializeMsal = async () => {
      await pca.initialize();
      await pca.handleRedirectPromise();
      const account = getAccount(pca);
      if (!account) {
        handleLoggedOutUser();
        return;
      }
      setLoggedInAccount(account);
    }
    initializeMsal();
  }, []);

  useEffect(() => {
    // Login in progress
    setAuthState({ ...authState, loginState: LoginState.IN_PROGRESS });
    const login = async (account: AccountInfo) => {
      const token = await getAccessToken(account, loginRequest.scopes, pca);
      const profilePicture = await getProfilePicture(account, pca);

      // No token available
      if (!token) {
        setAuthState({
          ...authState,
          token: 'DEV_TOKEN',
          loginState: LoginState.LOGGED_IN,
          account,
          roles: ['Guest'],
          profilePicture: '',
        });
      } else {
        // Success!
        setAuthState({
          ...authState,
          token,
          loginState: LoginState.LOGGED_IN,
          account,
          roles: account?.idTokenClaims?.roles
            ? (account?.idTokenClaims?.roles)
            : ['Guest'],
          profilePicture,
        });
      }
    };

    // No signed in user since no account
    if (!loggedInAccount) {
      return;
    }
    login(loggedInAccount);

    /* Refresh token every 29 minutes */
    const timer = setInterval(async () => {
      try {
        await login(loggedInAccount);
      } catch (err) {
        clearInterval(timer);
      }
    }, 1000 * 60 * 29);
  }, [loggedInAccount]);

  return (
    <MsalContext.Provider value={authState}>
      {authState.loginState === LoginState.LOGGED_IN && authState.token && children}
    </MsalContext.Provider>
  );
};
