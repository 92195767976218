import React, { useState, useEffect } from 'react';
import { Card, CardContent, Chip, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { StyledSelectableCardGrid } from '.';
import { FilteredRxsWinProps, SelectableCardsValues } from '~constants';
import { filterNewRxsWin, filterUpdatedRxsWin, groupRxsWin, groupSoftware } from '~helpers';

interface SelectableCardsProps {
  loading: boolean;
  rxsWin: FilteredRxsWinProps[] | undefined;
  setSelectedCard: React.Dispatch<React.SetStateAction<SelectableCardsValues>>;
  selectedCard: string;
}

interface CardDataProps {
  card: SelectableCardsValues;
  quantity: number;
}

export const SelectableCards = ({
  loading,
  rxsWin,
  setSelectedCard,
  selectedCard,
}: SelectableCardsProps) => {
  const theme = useTheme();
  const [cardData, setCardData] = useState<CardDataProps[]>([]);

  useEffect(() => {
    const formattedRxsWin = rxsWin?.map((item) => ({
      rxsWin: item.rxsWin,
      approvalNumber: item.approvalNumber,
      softwarePartNumber: item.softwarePartNumber,
      date: item.date,
    }));

    setCardData([
      { card: SelectableCardsValues.SWReleases, quantity: groupSoftware(formattedRxsWin)?.length || 0 },
      {
        card: SelectableCardsValues.NewRxsWins,
        quantity: filterNewRxsWin(groupRxsWin(formattedRxsWin))?.length || 0,
      },
      {
        card: SelectableCardsValues.RxsWinUpdates,
        quantity: filterUpdatedRxsWin(groupRxsWin(formattedRxsWin))?.length || 0,
      },
    ]);
  }, [rxsWin]);

  return (
    <Grid
      container
      sx={{
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
    >
      {cardData.map((item) => (
        <StyledSelectableCardGrid
          item
          key={item.card}
          className={selectedCard === item.card ? 'active' : undefined}
          onClick={() => setSelectedCard(item.card)}
          data-testid={item.card}
          xs={4}
          md={4}
          lg={6}
        >
          <Card
            sx={{ height: '100%', position: 'relative', ':hover': { boxShadow: 3 } }}
            elevation={selectedCard === item.card ? 2 : 1}
          >
            <CardContent>
              <Chip
                color={selectedCard === item.card ? 'primary' : 'default'}
                label={item.card}
                variant={selectedCard === item.card ? 'filled' : 'outlined'}
              />
              <Typography
                variant="h2"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {loading ? <Skeleton width={50} /> : item.quantity}
              </Typography>
            </CardContent>
          </Card>
        </StyledSelectableCardGrid>
      ))}
    </Grid>
  );
};
