import { ColDef } from 'ag-grid-community';
import { FilteredRxsWinProps, SelectableCardsValues } from '~constants';

const gridHeaders: Record<string, string> = {
  rxsWin: 'RXSWIN',
  approvalNumber: 'Type Approval',
  softwarePartNumber: 'SW Part Number',
};

export const getDeclarationsDatagrid = (
  rxsWin: FilteredRxsWinProps[],
  selectedCard: SelectableCardsValues,
) => {
  const formattedRxsWin = rxsWin?.map((item) => ({
    rxsWin: item.rxsWin,
    approvalNumber: item.approvalNumber,
    softwarePartNumber: item.softwarePartNumber,
  }));

  const columnDefs: ColDef[] = Object.keys(gridHeaders).map((key) => ({
    field: key,
    headerName: gridHeaders[key],
    tooltipField: key,
    rowGroup: ((selectedCard === SelectableCardsValues.RxsWinUpdates || selectedCard === SelectableCardsValues.NewRxsWins) && key === 'rxsWin') ||
    (selectedCard===SelectableCardsValues.SWReleases && key === 'softwarePartNumber'),
    hide: ((selectedCard === SelectableCardsValues.RxsWinUpdates || selectedCard === SelectableCardsValues.NewRxsWins) && key === 'rxsWin') ||
    (selectedCard===SelectableCardsValues.SWReleases && key === 'softwarePartNumber'),
  }));

  const rowData = formattedRxsWin?.map((item) => ({ ...item }));

  return { columnDefs, rowData };
};
