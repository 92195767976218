import { GridApi } from 'ag-grid-community';
import { Button, styled } from '@mui/material';

interface StyledClearFilterButtonProps {
  gridApi: GridApi | null | undefined;
  children: JSX.Element;
}

const StyledClearFilterBtn = styled(Button)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  right: '50px',
  top: '45px',
  minHeight: '40px',
  height: '40px',
  minWidth: '40px',
  width: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette.error.lighter,
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
}));

export const StyledClearFilterButton = ({ gridApi, children }: StyledClearFilterButtonProps) => {
  const clearDataGridFilters = () => {
    gridApi?.setFilterModel(null);
  };

  return (
    <StyledClearFilterBtn onClick={() => clearDataGridFilters()}>{children}</StyledClearFilterBtn>
  );
};
