export enum ToggleTabsValues {
  LastSixWeeks = 'lastsixweeks',
  ThreeMonths = 'three',
  SixMonths = 'six',
}

export enum SelectableCardsValues {
  SWReleases = 'SW Releases',
  NewRxsWins = 'New RXSWINs',
  RxsWinUpdates = 'RXSWIN Updates',
}

export enum FilterTypeValues {
  Filters = 'Filters',
  Vin = 'VIN Search',
  SW = 'SW Search',
  None = 'None',
}
