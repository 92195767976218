import { Dispatch, SetStateAction, useMemo } from 'react';
import { FormControl, TextField, Box } from '@mui/material';
import { FiltersProps } from './AuditableRegister';
import { Button } from '~components';
import { IFilterCommonProps } from './types';

export enum SearchType {
  Vin = 'VIN',
  SW = 'SW Part Number',
}

interface SearchProps extends IFilterCommonProps {
  type: SearchType;
  setValue: Dispatch<SetStateAction<FiltersProps>>;
}

export const Search = ({ filters, type, setValue, getRxsWin }: SearchProps) => {
  const { id, label, testId }: { id: keyof FiltersProps; label: SearchType; testId: string } =
    useMemo(
      () => ({
        id: type === SearchType.Vin ? 'vin' : 'softwarePartNumber',
        label: type,
        testId: type === SearchType.Vin ? 'vinFilter' : 'swFilter',
      }),
      [type],
    );

  const onSearchClickHandler = () => {
    setValue(() => ({
      ...filters,
      brand: [],
      regulation: [],
      typeApprovals: '',
      rxsWin: '',
      ...(type === SearchType.Vin ? { softwarePartNumber: '' } : { vin: '' }),
    }));
    getRxsWin({
      brand: [],
      regulation: [],
      typeApprovals: '',
      rxsWin: '',
      ...(type === SearchType.Vin
        ? { vin: filters.vin, softwarePartNumber: '' }
        : { vin: '', softwarePartNumber: filters.softwarePartNumber }),
    });
  };

  return (
    <FormControl fullWidth>
      <TextField
        sx={{ mt: 1 }}
        size="small"
        id={id}
        label={label}
        data-testid={testId}
        value={filters[id]}
        onChange={(e) => setValue({ ...filters, [id]: e.target.value })}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          sx={{
            width: '100%',
            mr: 1,
          }}
          data-testid="clearVinBtn"
          disabled={!filters[id]}
          onClick={() => setValue({ ...filters, [id]: '' })}
        >
          Clear
        </Button>
        <Button
          sx={{
            ml: 1,
            width: '100%',
          }}
          variant="outlined"
          data-testid="vinSearchBtn"
          disabled={!filters[id]}
          onClick={onSearchClickHandler}
        >
          Search
        </Button>
      </Box>
    </FormControl>
  );
};
