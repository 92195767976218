import { Paper, Tab, Tabs, styled } from '@mui/material';
import { SyntheticEvent } from 'react';
import { ToggleTabsValues } from '~constants';

interface ToggleTabsProps {
  setActiveTab: React.Dispatch<React.SetStateAction<ToggleTabsValues>>;
  activeTab: string;
}

export const ToggleTabs = ({ setActiveTab, activeTab }: ToggleTabsProps) => {
  const StyledTabs = styled(Tab)(({ theme }) => ({
    padding: 'unset',
    height: 56,
    margin: `0 ${theme.spacing(1)}`,
    borderRadius: `${theme.spacing(1)}`,
  }));

  const handleChange = (e: SyntheticEvent, nextTab: ToggleTabsValues) => setActiveTab(nextTab);

  return (
    <Paper sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tabs
        textColor="secondary"
        indicatorColor="secondary"
        onChange={handleChange}
        value={activeTab}
        aria-label="Tabs to toggle data selection"
      >
        <StyledTabs value={ToggleTabsValues.LastSixWeeks} label="Last 6 weeks" />
        <StyledTabs value={ToggleTabsValues.ThreeMonths} label="Last 3 months" />
        <StyledTabs value={ToggleTabsValues.SixMonths} label="Last 6 months" />
      </Tabs>
    </Paper>
  );
};
