import { useMemo } from 'react';
import { Tab, Tabs, useTheme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

export const NavBarLinks = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const color = useMemo(
    () => (theme.palette.mode === 'dark' ? 'primary' : 'secondary'),
    [theme.palette.mode],
  );
  return (
    <Tabs
      value={pathname}
      textColor={color}
      indicatorColor={color}
      sx={{ '& .MuiTabs-flexContainer': { height: 64 } }}
    >
      <Tab value="/" to="/" label="Declarations" component={NavLink} disableRipple />
      <Tab
        value="/auditable-register"
        to="/auditable-register"
        label="Auditable Register"
        component={NavLink}
        disableRipple
      />
    </Tabs>
  );
};
