import { PaletteMode, createTheme, responsiveFontSizes } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles';
import { DEFAULT_GUTTERS, SPACING } from './constants';
import { overrides, typography } from '.';

interface CustomPaletteColorOptions {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}

interface CustomThemeOptions {
  drawer: {
    width: number;
  };
  logo: {
    width: number;
    height: number;
  };
  overrides: ComponentsOverrides;
  defaultGutters: number;
  responsiveSpacing: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
}

declare module '@mui/material' {
  interface Theme extends CustomThemeOptions {}
  interface ThemeOptions extends CustomThemeOptions {}
  interface PaletteColor extends CustomPaletteColorOptions {}
  interface PaletteColorOptions extends CustomPaletteColorOptions {}
}

export const getTheme = (mode: PaletteMode) => {
  const theme = responsiveFontSizes(
    createTheme({
      spacing: SPACING,
      typography,
      overrides,
      drawer: {
        width: 240,
      },
      shape: {
        borderRadius: 10,
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundImage: 'none',
            },
          },
        },
      },
      palette: {
        mode,
        common: {
          black: '#000',
          white: '#fff',
        },
        primary: {
          lighter: 'rgba(255, 247, 225, 1)',
          light: 'rgba(255, 233, 180, 1)',
          main: 'rgba(254, 207, 86, 1)',
          dark: 'rgba(251, 171, 31, 1)',
          contrastText: 'rgba(5, 7, 9, 1)',
        },
        // Green
        success: {
          lighter: '#b6da95',
          light: '#97c969',
          main: '#76a455',
          dark: '#476d3b',
          contrastText: '#f2f9ec',
        },
        // Orange
        warning: {
          lighter: '#ffce88',
          light: '#ffaa3b',
          main: '#ff9027',
          dark: '#f17123',
          contrastText: '#fff3e2',
        },
        // Blue
        info: {
          lighter: '#bcdeff',
          light: '#65b6ff',
          main: '#2797ff',
          dark: '#2465ca',
          contrastText: '#e3f2ff',
        },
        // Red
        error: {
          lighter: '#ffcad1',
          light: '#ee6a6e',
          main: '#ff272b',
          dark: '#d1001e',
          contrastText: '#ffeaee',
        },
        ...(mode === 'light'
          ? {
              background: {
                paper: 'rgba(255, 255, 255, 1)',
                default: 'rgba(245, 245, 245, 1)',
              },
              secondary: {
                lighter: '#eaeaea',
                light: '#979797',
                main: '#3c3c3c',
                dark: '#1c1c1c',
                contrastText: '#fff',
              },
              text: {
                primary: 'rgba(60, 60, 60, 1)',
                secondary: 'rgba(129, 129, 129, 1)',
                disabled: 'rgba(194, 194, 194, 1)',
              },
            }
          : {
              background: {
                paper: '#393939',
                default: '#282828',
              },
              secondary: {
                lighter: '#ffffff',
                light: '#fafafa',
                main: '#f5f5f5',
                dark: '#c2c2c2',
                contrastText: '#606060',
              },
              text: {
                primary: '#fff',
                secondary: 'rgba(255, 255, 255, 0.7)',
                disabled: 'rgba(255, 255, 255, 0.5)',
              },
            }),
      },
      logo: {
        width: 51,
        height: 51,
      },
      defaultGutters: DEFAULT_GUTTERS,
      responsiveSpacing: {
        xs: SPACING * 1.5,
        sm: SPACING * 2,
        md: SPACING * 4,
        lg: SPACING * 4,
        xl: SPACING * 4,
      },
    }),
  );

  theme.shadows[1] = 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px';
  theme.shadows[2] =
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px';
  theme.shadows[3] =
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px';
  theme.shadows[4] =
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px';
  theme.shadows[5] =
    'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px';

  return theme;
};
