/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';
import { WrapperCard } from '~components';

export const DataGridHoverTooltip = (props: ITooltipParams) => {
  const { api, rowIndex } = props;
  const rowData = useMemo(() => api.getDisplayedRowAtIndex(rowIndex!)!.data, []);

  return (
    <WrapperCard>
      <Typography>
        Click to view more of SW Part Number: {rowData.softwarePartNumber} in Auditable Register
      </Typography>
    </WrapperCard>
  );
};
