import { createContext } from 'react';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';

export enum LoginState {
  IN_PROGRESS,
  LOGGED_IN,
  LOGGED_OUT,
}

export interface AuthorizationState {
  token: string;
  loginState: LoginState;
  account: AccountInfo | null;
  roles: string[];
  profilePicture: string;
  instance?: PublicClientApplication | null;
}

export const defaultAuthState: AuthorizationState = {
  loginState: LoginState.LOGGED_OUT,
  token: '',
  account: null,
  roles: ['Guest'],
  profilePicture: '',
  instance: null,
};

export const MsalContext = createContext<AuthorizationState>(defaultAuthState);
