/* eslint-disable react/jsx-props-no-spreading */
import { Button as MuiButton, ButtonProps, useTheme } from '@mui/material';

export const Button = ({ children, sx, ...props }: ButtonProps) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  return (
    <MuiButton
      color={darkMode ? 'primary' : 'secondary'}
      sx={{
        ...sx,
        ':hover': {
          backgroundColor: darkMode ? theme.palette.primary.main : theme.palette.secondary.main,
          color: darkMode ? theme.palette.primary.lighter : theme.palette.secondary.lighter,
        },
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};
