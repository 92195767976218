/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup,
  RadioProps,
  useTheme,
} from '@mui/material';

interface GroupOptionsProps {
  groupByValue: string;
  setGroupByValue: React.Dispatch<React.SetStateAction<string>>;
}

const Radio = (props: RadioProps) => {
  const theme = useTheme();
  return <MuiRadio {...props} color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'} />;
};

export const GroupOptions = ({ groupByValue, setGroupByValue }: GroupOptionsProps) => (
  <RadioGroup
    aria-labelledby="group-by-label"
    name="radio-buttons-group"
    value={groupByValue}
    onChange={(e) => {
      setGroupByValue(e.target.value);
    }}
  >
    <FormControlLabel
      data-testid="groupByBaseRxsWin"
      value="baseRxsWin"
      control={<Radio />}
      label="Base RXSWIN"
    />
    <FormControlLabel
      data-testid="groupBySoftwarePartNumber"
      value="softwarePartNumber"
      control={<Radio />}
      label="SW Part Number"
    />
  </RadioGroup>
);
