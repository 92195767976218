import { ReactNode } from 'react';
import { SxProps, Theme, useTheme, Card } from '@mui/material';

interface WrapperCardProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const WrapperCard = ({ children, sx }: WrapperCardProps) => {
  const theme = useTheme();
  return <Card sx={{ boxShadow: 1, padding: theme.spacing(2), ...sx }}>{children}</Card>;
};
