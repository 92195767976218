import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Badge, Card, CardContent, CardHeader, Divider, Grid, Tabs, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filters } from './Filters';
import {
  AccessLevel,
  AuditableRegistryRow,
  useGetExternalAuditableRegistryRowsBySwPartNumberLazyQuery,
  useGetExternalAuditableRegistryRowsByVinLazyQuery,
  useGetExternalAuditableRegistryRowsLazyQuery,
  useGetInternalAuditableRegistryRowsBySwPartNumberLazyQuery,
  useGetInternalAuditableRegistryRowsByVinLazyQuery,
  useGetInternalAuditableRegistryRowsLazyQuery,
  useGetUserQuery,
} from '~codegen/types-and-hooks';
import { getAuditableRegisterDatagrid, getAuditableRegisterHeaders } from '~helpers';
import { defaultFilters, useStorageFilters } from '~hooks';
import { GroupOptions } from './GroupOptions';
import { DataGrid, Tab, TabPanel } from '~components';
import { Search, SearchType } from './Search';
import { FilterTypeValues } from '~constants';

export interface FiltersProps {
  vin: string;
  brand: string[];
  regulation: string[];
  typeApprovals: string;
  rxsWin: string;
  softwarePartNumber: string;
}

interface LocationState {
  state: { softwarePartNumber: string };
}

interface GenericAuditableRegistryRow extends Omit<AuditableRegistryRow, "ecuCorePartNumber"> {
  ecuCorePartNumber?: string;
}

export const AuditableRegister = () => {
  const theme = useTheme();
  const location = useLocation() as LocationState;
  const navigate = useNavigate();
  const locationPartNumber = location.state ? location.state.softwarePartNumber : '';
  const { hasStorageFilters, storageFilters, filteredBy, setStorageFilters, setFilteredBy } =
    useStorageFilters();
  const [groupByValue, setGroupByValue] = useState<string>('baseRxsWin');

  const getInitialTab = () => {
    if (locationPartNumber) {
      return FilterTypeValues.SW;
    }
    if (filteredBy !== FilterTypeValues.None) {
      return filteredBy || FilterTypeValues.Filters;
    }
    return FilterTypeValues.Filters;
  };

  const [activeTab, setActiveTab] = useState<FilterTypeValues>(getInitialTab());
  const [queriedFilters, setQueriedFilters] = useState<FiltersProps>(storageFilters);
  const [rowData, setRowData] = useState<Record<string, string>[] | undefined>(
    !hasStorageFilters && !locationPartNumber ? [] : undefined,
  );
  const { data: userData, loading: userDataLoading } = useGetUserQuery();

  const color = useMemo(
    () => (theme.palette.mode === 'dark' ? 'primary' : 'secondary'),
    [theme.palette.mode],
  );

  useEffect(() => {
    if (!hasStorageFilters) {
      setRowData([]);
      setFilteredBy(FilterTypeValues.None);
    }
  }, [hasStorageFilters]);

  const handleRowData = (
    data: Array<GenericAuditableRegistryRow >,
  ) => {
    const formattedRowData = getAuditableRegisterDatagrid(
      data,
      groupByValue,
      storageFilters,
    );
    setQueriedFilters(storageFilters);
    setRowData(formattedRowData);
  };

  const [getInternalRxsWin, { loading: internalRxsWinLoading }] =
    useGetInternalAuditableRegistryRowsLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRows } = data;
        handleRowData(auditableRegistryRows);
      },
    });

  const [getInternalRxsWinByVin, { loading: internalRxsWinByVinLoading }] =
    useGetInternalAuditableRegistryRowsByVinLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRowsForVin } = data;
        handleRowData(auditableRegistryRowsForVin);
      },
    });

  const [getInternalRxsWinBySWPartNumber, { loading: internalRxsWinBySWPartNumberLoading }] =
    useGetInternalAuditableRegistryRowsBySwPartNumberLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRowsForSoftware } = data;
        handleRowData(auditableRegistryRowsForSoftware);
      },
    });

  const [getExternalRxsWin, { loading: externalRxsWinLoading }] =
    useGetExternalAuditableRegistryRowsLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRows } = data;
        handleRowData(auditableRegistryRows);
      },
    });

  const [getExternalRxsWinByVin, { loading: externalRxsWinByVinLoading }] =
    useGetExternalAuditableRegistryRowsByVinLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRowsForVin } = data;
        handleRowData(auditableRegistryRowsForVin);
      },
    });

  const [getExternalRxsWinBySWPartNumber, { loading: externalRxsWinBySWPartNumberLoading }] =
    useGetExternalAuditableRegistryRowsBySwPartNumberLazyQuery({
      onCompleted: (data) => {
        const { auditableRegistryRowsForSoftware } = data;
        handleRowData(auditableRegistryRowsForSoftware);
      },
    });

  const handleInternalRxsWinQuery = (filters: FiltersProps) => {
    setFilteredBy(activeTab);
    if (activeTab === FilterTypeValues.Filters) {
      return getInternalRxsWin({ variables: filters });
    }
    if (activeTab === FilterTypeValues.Vin) {
      return getInternalRxsWinByVin({ variables: filters });
    }
    return getInternalRxsWinBySWPartNumber({ variables: filters });
  };

  const handleExternalRxsWinQuery = (filters: FiltersProps) => {
    setFilteredBy(activeTab);
    if (activeTab === FilterTypeValues.Filters) {
      return getExternalRxsWin({ variables: filters });
    }
    if (activeTab === FilterTypeValues.Vin) {
      return getExternalRxsWinByVin({ variables: filters });
    }
    return getExternalRxsWinBySWPartNumber({ variables: filters });
  };

  useEffect(() => {
    const getData = (filters: FiltersProps) => {
      if (userData?.user.accessLevel === AccessLevel.Internal) {
        handleInternalRxsWinQuery(filters);
      } else if (userData?.user.accessLevel === AccessLevel.External) {
        handleExternalRxsWinQuery(filters);
      }
    };

    if (locationPartNumber) {
      navigate('.', { replace: true });
      setActiveTab(FilterTypeValues.SW);
      setStorageFilters({ ...defaultFilters, softwarePartNumber: locationPartNumber });
      getData({ ...defaultFilters, softwarePartNumber: locationPartNumber });
    } else {
      const hasStorageFilters = Object.values(storageFilters).some((filter) => filter?.length);
      if (!hasStorageFilters) {
        setRowData([]);
      } else {
        getData(storageFilters);
      }
    }
  }, [userData]);

  const columnDefs = getAuditableRegisterHeaders(
    groupByValue,
    queriedFilters,
    userData?.user.accessLevel,
  );

  return (
    <Grid container sx={{ backgroundColor: theme.palette.background.default }}>
      <Grid xs={12} md={4} xl={3} item sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{ p: 0 }}
                title={
                  <Tabs
                    variant="fullWidth"
                    textColor={color}
                    indicatorColor={color}
                    aria-label="Tabs to toggle data selection"
                    onChange={(e: SyntheticEvent, nextTab: FilterTypeValues) =>
                      setActiveTab(nextTab)
                    }
                    value={activeTab}
                  >
                    {Object.values(FilterTypeValues).map(
                      (tab) =>
                        tab !== FilterTypeValues.None && (
                          <Tab
                            key={tab}
                            label={
                              <Badge
                                color="warning"
                                variant="dot"
                                invisible={
                                  !hasStorageFilters || activeTab === tab || filteredBy !== tab
                                }
                              >
                                {tab}
                              </Badge>
                            }
                            value={tab}
                          />
                        ),
                    )}
                  </Tabs>
                }
              />
              <Divider />
              <CardContent>
                <TabPanel hidden={activeTab !== FilterTypeValues.Filters}>
                  <Filters
                    filters={storageFilters}
                    setFilters={setStorageFilters}
                    getRxsWin={
                      userData?.user.accessLevel === AccessLevel.Internal
                        ? handleInternalRxsWinQuery
                        : handleExternalRxsWinQuery
                    }
                  />
                </TabPanel>
                <TabPanel hidden={activeTab !== FilterTypeValues.Vin}>
                  <Search
                    filters={storageFilters}
                    type={SearchType.Vin}
                    getRxsWin={
                      userData?.user.accessLevel === AccessLevel.Internal
                        ? handleInternalRxsWinQuery
                        : handleExternalRxsWinQuery
                    }
                    setValue={setStorageFilters}
                  />
                </TabPanel>
                <TabPanel hidden={activeTab !== FilterTypeValues.SW}>
                  <Search
                    filters={storageFilters}
                    type={SearchType.SW}
                    getRxsWin={
                      userData?.user.accessLevel === AccessLevel.Internal
                        ? handleInternalRxsWinQuery
                        : handleExternalRxsWinQuery
                    }
                    setValue={setStorageFilters}
                  />
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Group By" titleTypographyProps={{ variant: 'button' }} />
              <Divider />
              <CardContent>
                <GroupOptions groupByValue={groupByValue} setGroupByValue={setGroupByValue} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} xl={9}>
        <DataGrid
          loading={
            internalRxsWinLoading ||
            externalRxsWinLoading ||
            internalRxsWinByVinLoading ||
            externalRxsWinByVinLoading ||
            internalRxsWinBySWPartNumberLoading ||
            externalRxsWinBySWPartNumberLoading ||
            userDataLoading
          }
          formattedColumnDefs={columnDefs}
          formattedRowData={rowData}
          isAuditableRegisterView
          groupByValue={groupByValue}
        />
      </Grid>
    </Grid>
  );
};
