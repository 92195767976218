import { Box, Card, Typography, useTheme } from '@mui/material';

interface RoleProtectedRouteProps {
  roles: string[];
  children: JSX.Element;
}

export const RoleProtectedRoute = ({ roles, children }: RoleProtectedRouteProps): JSX.Element => {
  const theme = useTheme();

  if (roles.includes('Guest'))
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '250px',
            width: '650px',
            padding: theme.spacing(3),
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: theme.spacing(3) }}>
            We&apos;re sorry, but it looks like you have not received a role yet.
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: theme.spacing(3) }}>
            Please try again later.
          </Typography>
        </Card>
      </Box>
    );
  return <>{children}</>;
};
