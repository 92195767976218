export const setCssVariables = (mode: string) => {
  // clear the built in variables
  document.body.style.setProperty('--ag-background-color', '');
  document.body.style.setProperty('--ag-odd-row-background-color', '');
  document.body.style.setProperty('--ag-header-background-color', '');
  document.body.style.setProperty('--ag-font-size', '');
  document.body.style.setProperty('--custom-color-scheme', mode);
  if (mode === 'dark') {
    document.body.style.setProperty('--custom-active-color', '#2196f3');
    document.body.style.setProperty('--custom-background-color', '#393939');
    document.body.style.setProperty('--custom-foreground-color', '#fff');
    document.body.style.setProperty('--custom-border-color', 'rgba(255, 255, 255, 0.12)');
    document.body.style.setProperty('--custom-secondary-border-color', 'rgba(#585652, 0.5)');
    document.body.style.setProperty('--custom-header-background-color', '#393939');
    document.body.style.setProperty('--custom-odd-row-background-color', '#3c3c3c');
    document.body.style.setProperty('--custom-control-panel-background-color', '#222628');
    document.body.style.setProperty('--custom-subheader-background-color', '#000');
    document.body.style.setProperty('--custom-input-focus-box-shadow', '0 0 0px 0px #f78b08');
    document.body.style.setProperty('--custom-card-shadow', '0 1px 20px 1px black');
    document.body.style.setProperty('--custom-invalid-color', '#e02525');
    document.body.style.setProperty('--custom-modal-overlay-background-color', '#333333');
    document.body.style.setProperty('--custom-row-border-color', '#333333');
  } else {
    document.body.style.setProperty('--custom-active-color', '#2196f3');
    document.body.style.setProperty('--custom-background-color', 'rgba(255, 255, 255, 1)');
    document.body.style.setProperty('--custom-foreground-color', '#181d1f');
    document.body.style.setProperty('--custom-border-color', 'rgba(0, 0, 0, 0.12)');
    document.body.style.setProperty('--custom-secondary-border-color', '#dde2eb');
    document.body.style.setProperty('--custom-header-background-color', 'rgba(255, 255, 255, 1)');
    document.body.style.setProperty('--custom-odd-row-background-color', '#fcfcfc');
    document.body.style.setProperty('--custom-control-panel-background-color', '#f8f8f8');
    document.body.style.setProperty('--custom-subheader-background-color', '#fff');
    document.body.style.setProperty('--custom-invalid-color', '#e02525');
    document.body.style.setProperty('--custom-modal-overlay-background-color', '#ffffff');
    document.body.style.setProperty('--custom-row-border-color', '#dde2eb');
  }
};
