import { TypographyVariantsOptions } from '@mui/material/styles';

export const BOLD = 500;
export const REGULAR = 'normal';

export const typography: TypographyVariantsOptions = {
  h1: {
    fontSize: 70,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  h2: {
    fontSize: 50,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  h3: {
    fontSize: 35,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  h4: {
    fontSize: 23,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  h5: {
    fontSize: 20,
    fontWeight: BOLD,
    lineHeight: 1.125,
  },
  body1: {
    fontWeight: REGULAR,
    lineHeight: 1.5,
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontFamily: 'inherit',
  },
  subtitle2: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: BOLD,
  },
  fontFamily: ['Volvo Novum', 'Arial', 'sans-serif'].join(','),
};
