/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction } from 'react';
import {
  AutocompleteProps,
  Box,
  CircularProgress,
  FormControl,
  Autocomplete as MUIAutocomplete,
  TextField,
  useTheme,
} from '@mui/material';
import {
  useGetDropdownDataQuery,
} from '~codegen/types-and-hooks';
import { FiltersProps } from './AuditableRegister';
import { Button } from '~components';
import { IFilterCommonProps } from './types';

interface IAutocomplete<T>
  extends Omit<
    AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
    'renderInput'
  > {
  label: string;
}

interface IFilters extends IFilterCommonProps{
  setFilters: Dispatch<SetStateAction<FiltersProps>>;
  loading?: boolean;
}

const Autocomplete = <T,>({ label, ...props }: IAutocomplete<T>) => {
  const theme = useTheme();
  return (
  <MUIAutocomplete
    sx={{
      mt: 1,
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root.MuiInputBase-root': {
          paddingRight: `36px`,
        },
      },
    }}
    size="small"
    {...props}
    renderInput={(params) => (
      <TextField
        color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
)};

export const Filters = ({ filters, setFilters, getRxsWin, loading }: IFilters) => {
  const {
    loading: dropdownDataLoading,
    error: dropdownDataError,
    data,
  } = useGetDropdownDataQuery();

  const filtersOptionsHandler = (key: string, value: string[] | string | null) => {
    setFilters((prevState: FiltersProps) => ({
      ...prevState,
      ...{ [key]: value ?? '' },
    }));
  };

  const getBrandOptions = () => [...new Set(data?.dropdownData.map((record) => record.brand))];

  const getRegulationOptions = (brands: string[]) => [
    ...new Set(
      data?.dropdownData
        .filter((record) => brands.includes(record.brand))
        .map((record) => record.regulation),
    ),
  ];

  const getTypeApprovalOptions = (brands: string[], regulations: string[]) => [
    ...new Set(
      data?.dropdownData
        .filter((record) => brands.includes(record.brand) && regulations.includes(record.regulation))
        .map((record) => record.typeApprovals)
        .reduce((options, record) => [...options, ...record], []),
    ),
  ];

  const getRxsWinOptions = (brands: string[], regulations: string[]) => [
    ...new Set(
      data?.dropdownData
        .filter((record) => brands.includes(record.brand) && regulations.includes(record.regulation))
        .map((record) => record.rxsWin),
    ),
  ];

  const handleBrandDependencies = (newValue: string[]) => {
    let newFilters = {
      regulation:
        filters.regulation && !getRegulationOptions(newValue).some(regulation => filters.regulation.includes(regulation))
          ? []
          : filters.regulation.filter(regulation => getRegulationOptions(newValue).includes(regulation)),
      typeApprovals:
        filters.typeApprovals &&
        !getTypeApprovalOptions(newValue, filters.regulation).includes(filters.typeApprovals)
          ? ''
          : filters.typeApprovals,
      rxsWin:
        filters.rxsWin && !getRxsWinOptions(newValue, filters.regulation).includes(filters.rxsWin)
          ? ''
          : filters.rxsWin,
    };

    if (newValue.length < 1) {
      newFilters = {
        regulation: [],
        typeApprovals: '',
        rxsWin: '',
      };
    }
    setFilters((prevState: FiltersProps) => ({
      ...prevState,
      ...newFilters,
    }));
  };

  const handleRegulationDependencies = (newValue: string[]) => {
    const newFilters = {
      typeApprovals:
        filters.typeApprovals &&
        !getTypeApprovalOptions(filters.brand, newValue).includes(filters.typeApprovals)
          ? ''
          : filters.typeApprovals,
      rxsWin:
        filters.rxsWin && !getRxsWinOptions(filters.brand, newValue).includes(filters.rxsWin)
          ? ''
          : filters.rxsWin,
    };

    setFilters((prevState: FiltersProps) => ({
      ...prevState,
      ...newFilters,
    }));
  };

  const onClearClickHandler = () => {
    setFilters(() => ({
      ...filters,
      brand: [],
      regulation: [],
      typeApprovals: '',
      rxsWin: '',
    }));
  };

  const onSearchClickHandler = () => {
    setFilters(() => ({
      ...filters,
      vin: '',
      softwarePartNumber: '',
    }))
    getRxsWin(filters);
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        label="Brand *"
        multiple
        id="brand"
        data-testid="brandFilter"
        onChange={(_e, newValue) => {
          filtersOptionsHandler('brand', newValue);
          handleBrandDependencies(newValue as string[]);
        }}
        disabled={dropdownDataLoading || loading}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getBrandOptions()}
        value={filters.brand || null}
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="Regulation *"
        multiple
        id="legal-regulation"
        data-testid="regulationFilter"
        onChange={(_e, newValue) => {
          filtersOptionsHandler('regulation', newValue);
          handleRegulationDependencies(newValue as string[]);
        }}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getRegulationOptions(filters.brand)}
        disabled={filters.brand.length < 1 || dropdownDataLoading || loading}
        value={filters.regulation || null}
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="Type Approval"
        id="type-approval"
        data-testid="typeApprovalFilter"
        onChange={(_e, newValue) => filtersOptionsHandler('typeApprovals', newValue)}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getTypeApprovalOptions(filters.brand, filters.regulation)}
        disabled={filters.brand.length < 1 || filters.regulation.length < 1 || dropdownDataLoading || loading}
        value={filters.typeApprovals || null}
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="RXSWIN"
        id="rxsWin"
        data-testid="rxsWinFilter"
        onChange={(_e, newValue) => filtersOptionsHandler('rxsWin', newValue)}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getRxsWinOptions(filters.brand, filters.regulation)}
        disabled={filters.brand.length < 1 || filters.regulation.length < 1 || dropdownDataLoading || loading}
        value={filters.rxsWin || null}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          sx={{
            width: '100%',
            mr: 1,
          }}
          data-testid="clearFiltersBtn"
          disabled={filters.brand.length < 1 && filters.regulation.length < 1}
          onClick={() => onClearClickHandler()}
        >
          Clear
        </Button>
        <Button
          sx={{
            ml: 1,
            width: '100%',
          }}
          variant="outlined"
          data-testid="filterBtn"
          disabled={filters.brand.length < 1 || filters.regulation.length < 1}
          onClick={() => onSearchClickHandler()}
        >
          Search
        </Button>
      </Box>
    </FormControl>
  );
};
