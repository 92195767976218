import { Avatar, IconButton, Tooltip, useTheme } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useContext } from 'react';
import { ColorModeContext } from '~utils/colorMode';

interface NavBarSettingsProps {
  userName?: string;
  roles: string[];
  profilePicture: string;
}

export const NavBarSettings = ({ userName, roles, profilePicture }: NavBarSettingsProps) => {
  const theme = useTheme();
  const { toggleColorMode } = useContext(ColorModeContext);
  return (
    <>
      <Tooltip title={`${userName}. Role: ${roles && roles?.map((role) => role).join(', ')}`}>
        <Avatar
          sx={{ width: 45, height: 45, margin: `0 ${theme.spacing(2)}` }}
          src={profilePicture}
          alt="profile picture"
        />
      </Tooltip>
      <IconButton onClick={toggleColorMode}>
        {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
      </IconButton>
    </>
  );
};
