import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import { telemetryClient } from '../utils/telemetry';
import { env } from '../config';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors?.forEach(({ message, locations, extensions, path }) => {
      telemetryClient.trackEvent({
        name: 'GraphQLError',
        properties: {
          Message: `${message}`,
          Location: locations,
          Extensions: extensions,
          Operation: operation,
          Path: path,
        },
      });
    });
  }

  if (networkError) {
    telemetryClient.trackEvent({
      name: 'NetworkError',
      properties: {
        Message: `${networkError}`,
        Operation: operation,
      },
    });
  }
});

const createAuthLink = (token: string) =>
  setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

export const createApolloClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  if (!token) throw new Error('No token provided to Apollo authLink');
  const authLink = createAuthLink(token);
  const backendLink = createHttpLink({ uri: env.graphqlEndpointUrl, fetch });

  const link = errorLink.concat(authLink.concat(backendLink));

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};
